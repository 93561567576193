<template>
  <div class="wrap">
    <el-table :data="list" class="myTab">
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="180"
      ></el-table-column>
      <el-table-column prop="id" label="问卷id" width="180"></el-table-column>
      <el-table-column
        prop="title2"
        label="问卷名称"
        width="180"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="edit(scope.row.id)"
            v-show="type != 'myAns'"
            >继续编辑</el-button
          >
          <el-button
            size="mini"
            type="success"
            @click="answer(scope.row.id, false)"
            v-show="type != 'myAns'"
            >开始答题</el-button
          >
          <el-button
            size="mini"
            type="success"
            @click="answer(scope.row.id, true)"
            >二次答题</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="check(scope.row.id)"
            v-show="type != 'myAns'"
            >批改问卷</el-button
          >
          <el-button size="mini" type="warning" @click="view(scope.row.id)"
            >立即查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      type: ""
    };
  },
  methods: {
    edit(id) {
      this.$router.push({
        path: "/edit",
        query: { questionId: id, createId: this.$route.query.createId }
      });
    },
    answer(id, boole) {
      this.$router.push({
        path: "/answer",
        query: {
          createId: this.$route.query.createId,
          questionId: id,
          answerAgain: boole,
          userId: 20
        }
      });
    },
    check(id) {
      this.$router.push({
        path: "/check",
        query: {
          checkId: this.$route.query.createId,
          questionId: id
        }
      });
    },
    view(id) {
      this.$router.push({
        path: `/view/${id}/${this.$route.query.userid}`
      });
    },
    //查询作业列表
    getlist() {
      this.$Api.Form.getFormList(this.$route.query.themeId)
        .then(res => {
          console.log(res);
          res.map(item => {
            item.title2 = JSON.parse(item.title).workname;
          })
          this.list = res;
        })
        .catch(err => {
          console.log(err);
        })
    }
  },
  mounted() {
    this.getlist();
    // console.log("问卷类型---", this.$route.query.type);
    // this.type = this.$route.query.type;
    // if (this.$route.query.type == "all") {
    //   this.$Api.Form.getFormList(this.$route.query.createId)
    //     .then((res) => {
    //       // console.log("全部调查问卷列表--", res);
    //       res.map((item) => {
    //         item.title2 = JSON.parse(item.title).workname;
    //       });
    //       this.list = res;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // } else if (this.$route.query.type == "myAns") {
    //   this.$Api.Form.getUserAnsList(this.$route.query.createId)
    //     .then((res) => {
    //       console.log("用户回答过的问卷列表", res);
    //       res.map((item) => {
    //         item.title2 = JSON.parse(item.qTitle).workname;
    //       });
    //       this.list = res;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }else if(this.$route.query.type == "myCheck"){
    //   this.$Api.Form.getCheckList(this.$route.query.createId)
    //       .then(res=>{
    //         console.log(res)
    //       })
    //       .catch(err=>console.log(err))
    // }
  }
};
</script>

<style lang="scss">
.wrap {
  .myTab {
    width: 80%;
    margin: 0 auto;
  }
}
</style>
